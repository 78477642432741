import store from '@/state/store';
import main from '../views/layouts/main';
import tracking from '../views/layouts/tracking';

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            offline: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next('/');
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: '/login/oauth/authorize',
        name: 'login-oauth',
        component: () => import('../views/pages/account/login'),
    },
    {
        path: '/token',
        name: 'token',
        component: () => import('../views/pages/auth/token'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' });
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next('/profile');
                } else {
                    // Continue to the forgot password page
                    next();
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            offline: true,
        },
        component: () => import('../views/pages/account/logout'),
    },
    {
        path: '/reset-password/:token',
        name: 'reset-password',
        component: () => import('../views/pages/account/reset-password'),
    },
    {
        path: '/verify/:token',
        name: 'verify',
        component: () => import('../views/pages/account/verify'),
    },
    {
        path: '/update-password',
        name: 'update-password',
        component: () => import('../views/pages/account/update-password'),
    },

    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
            offline: true,
            layout: main,
            allowedPermissions: ['DASHBOARD/VIEW'],
            notAllowedRedirect: 'tracking',
        },
        component: () => import('../views/pages/agents/agent-dashboard'),
    },
    {
        path: '/profile',
        name: 'profile',
        meta: { layout: main },
        component: () => import('../views/pages/account/profile'),
    },
    {
        path: '/profile/edit',
        name: 'edit-profile',
        meta: { layout: main },
        component: () => import('../views/pages/account/edit-profile'),
    },
    {
        path: '/profile/change-password',
        name: 'change-password',
        meta: { layout: main },
        component: () => import('../views/pages/account/change-password'),
    },

    {
        path: '/tracking',
        name: 'tracking',
        meta: {
            authRequired: true,
            layout: tracking,
            allowedPermissions: ['TRACKING'],
        },
        component: () => import('../views/pages/tracking/index.v2'),
    },
    {
        path: '/tracking-test-worker',
        name: 'tracking-test-worker',
        meta: {
            authRequired: true,
            layout: tracking,
            allowedPermissions: ['TRACKING'],
        },
        component: () => import('../views/pages/tracking/testWorker'),
    },
    {
        path: '/tracking/assettrip/:assetId?',
        name: 'trackingAssetTrip',
        meta: {
            authRequired: true,
            layout: tracking,
            allowedPermissions: ['TRACKING/ASSETTRIPS'],
        },
        component: () => import('../views/pages/tracking/assetTrip/index'),
    },
    {
        path: '/tracking/crash',
        name: 'crash-system',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN', 'TRACKING'],
        },
        component: () => import('../views/pages/tracking/crash/list'),
    },
    {
        path: '/old',
        name: 'index-old',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN', 'TRACKING'],
        },
        component: () => import('../views/pages/tracking/crash/index-old'),
    },
    {
        path: '/crash/edit/:crashId',
        name: 'auto-approve',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN', 'TRACKING'],
        },
        component: () => import('../views/pages/tracking/crash/approve-auto'),
    },
    {
        path: '/crash/create/:positionId',
        name: 'manual-approve',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN', 'TRACKING'],
        },
        component: () => import('../views/pages/tracking/crash/approve-manual'),
    },

    // {
    //     path: '/crash/auto/approve/:positionId',
    //     name: 'auto-approve',
    //     meta: {
    //         authRequired: true,
    //         layout: main,
    //         allowedPermissions: ['ADMIN', 'TRACKING'],
    //     },
    //     component: () => import('../views/pages/tracking/crash/approve-auto'),
    // },
    // {
    //     path: '/crash/manual/approve',
    //     name: 'manual-approve',
    //     meta: {
    //         authRequired: true,
    //         layout: main,
    //         allowedPermissions: ['ADMIN', 'TRACKING'],
    //     },
    //     component: () => import('../views/pages/tracking/crash/approve-manual'),
    // },
    {
        path: '/crash/approved',
        name: 'approved-crashes',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN', 'TRACKING'],
        },
        component: () => import('../views/pages/tracking/crash/approved-crashes'),
    },
    {
        path: '/crash/register/manual',
        name: 'manual-crash-register',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN', 'TRACKING'],
        },
        component: () => import('../views/pages/tracking/crash/manual-register'),
    },
    {
        path: '/crash/register/auto',
        name: 'auto-crash-register',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN', 'TRACKING'],
        },
        component: () => import('../views/pages/tracking/crash/auto-register'),
    },
    {
        path: '/crash/reject',
        name: 'reject-crash',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN', 'TRACKING'],
        },
        component: () => import('../views/pages/tracking/crash/rejected-crash-list'),
    },
    {
        path: '/tracking/hardware',
        name: 'hardware',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN', 'TRACKING'],
        },
        component: () => import('../views/pages/tracking/hardware/index'),
    },
    {
        path: '/tracking/hardware/create',
        name: 'hardware-create',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN', 'TRACKING'],
        },
        component: () => import('../views/pages/tracking/hardware/create'),
    },
    {
        path: '/tracking/hardware/edit/:id',
        name: 'hardware-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN', 'TRACKING'],
        },
        component: () => import('../views/pages/tracking/hardware/edit'),
    },
    {
        path: '/clients/drivers',
        name: 'client-drivers',
        meta: {
            authRequired: true,
            layout: tracking,
            allowedPermissions: ['ADMIN', 'CLIENTS'],
        },
        component: () => import('../views/pages/tracking/drivers/index'),
    },
    {
        path: '/tracking/leaflet',
        name: 'leaflet',
        meta: {
            authRequired: true,
            layout: tracking,
            allowedPermissions: ['TRACKING', 'CLIENTS'],
        },
        component: () => import('../views/pages/tracking/measuring'),
    },
    {
        path: '/tracking/testExport',
        name: 'leaflet',
        meta: {
            authRequired: true,
            layout: tracking,
            allowedPermissions: ['TRACKING', 'CLIENTS'],
        },
        component: () => import('../views/pages/tracking/testExport/index.vue'),
    },
    {
        path: '/tracking/geofence',
        name: 'trackingGeofence',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/GEOFENCING'],
        },
        component: () => import('../views/pages/tracking/geoFence/index'),
    },

    {
        path: '/tracking/geofence/geofence-add',
        name: 'tracking-Geofence-add',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/GEOFENCING/CREATE'],
        },
        component: () => import('../components/EezyTrack/GeoFence/GeoFenceAdd'),
    },
    {
        path: '/tracking/geofence/geofence-edit/:id',
        name: 'tracking-Geofence-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/GEOFENCING/EDIT'],
        },
        component: () => import('../components/EezyTrack/GeoFence/GeoFenceEdit'),
    },
    {
        path: '/tracking/poi',
        name: 'trackingPoi',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/POI'],
        },
        component: () => import('../views/pages/tracking/pointOfInterest/index'),
    },
    {
        path: '/tracking/poi/poi-add',
        name: 'tracking-poi-add',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/POI/CREATE'],
        },
        component: () => import('../components/EezyTrack/poi/PoiAdd'),
    },
    {
        path: '/tracking/poi/poi-edit/:id',
        name: 'tracking-poi-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/POI/EDIT'],
        },
        component: () => import('../components/EezyTrack/poi/PoiEdit'),
    },
    // {
    //     path: '/tracking/notification',
    //     name: 'notification',
    //     meta: {
    //         authRequired: true,
    //         layout: main,
    //     },
    //     component: () => import('../views/pages/tracking/notification/index'),
    // },
    {
        path: '/tracking/device',
        name: 'trackingDevice',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/DEVICES'],
        },
        component: () => import('../views/pages/tracking/device/index'),
    },
    {
        path: '/tracking/deviceHardware',
        name: 'trackingDeviceHardware',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/HARDWARE'],
        },
        component: () => import('../views/pages/tracking/deviceHardware/index'),
    },
    {
        path: '/tracking/deviceHardware/create',
        name: 'trackingDeviceHardwareCreate',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/DEVICES/CREATE'],
        },
        component: () => import('../views/pages/tracking/deviceHardware/create'),
    },
    {
        path: '/tracking/deviceHardware/edit/:id',
        name: 'trackingDeviceHardwareEdit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/HARDWARE'],
        },
        component: () => import('../views/pages/tracking/deviceHardware/edit'),
        props: true,
    },
    {
        path: '/tracking/hardwareCommand/edit',
        name: 'trackingHardwareCommandEdit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/DEVICES/EDIT'],
        },
        component: () => import('../views/pages/tracking/deviceHardware/commandEdit'),
    },
    {
        path: '/tracking/device-add',
        name: 'trackingDeviceAdd',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/DEVICES/CREATE'],
        },
        component: () => import('../views/pages/tracking/deviceAdd/index'),
    },
    {
        path: '/tracking/device-gps',
        name: 'trackingDeviceGPS',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/DEVICES/EDIT'],
        },
        component: () => import('../views/pages/tracking/deviceGPS/create'),
    },
    {
        path: '/tracking/device-edit/:id',
        name: 'trackingDeviceEdit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/DEVICES/EDIT'],
        },
        component: () => import('../views/pages/tracking/deviceEdit/index'),
    },
    {
        path: '/tracking/assets',
        name: 'trackingAssets',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/ASSETS'],
        },
        component: () => import('../views/pages/tracking/asset/index'),
    },
    {
        path: '/tracking/assets/create',
        name: 'tracking-assets-create',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/ASSETS/CREATE'],
        },
        component: () => import('../views/pages/tracking/asset/create'),
    },
    {
        path: '/tracking/assets/edit/:id',
        name: 'tracking-assets-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/ASSETS/EDIT'],
        },
        component: () => import('../views/pages/tracking/asset/edit'),
    },
    {
        path: '/tracking/assets-group',
        name: 'tracking-assets-group',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN', 'TRACKING'],
        },
        component: () => import('../views/pages/tracking/assetGroup/index.vue'),
    },
    {
        path: '/tracking/assets-group/create',
        name: 'tracking-assets-group-create',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN', 'TRACKING'],
        },
        component: () => import('../views/pages/tracking/assetGroup/create.vue'),
    },
    {
        path: '/tracking/assets-group/edit/:id',
        name: 'tracking-assets-group-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN', 'TRACKING'],
        },
        component: () => import('../views/pages/tracking/assetGroup/edit.vue'),
    },
    {
        path: '/tracking/assets-category',
        name: 'tracking-assets-category',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN', 'TRACKING'],
        },
        component: () => import('../views/pages/tracking/assetCategory/index.vue'),
    },
    {
        path: '/tracking/assets-category/create',
        name: 'tracking-assets-category-create',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN', 'TRACKING'],
        },
        component: () => import('../views/pages/tracking/assetCategory/create.vue'),
    },
    {
        path: '/tracking/assets-category/edit/:id',
        name: 'tracking-assets-category-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN', 'TRACKING'],
        },
        component: () => import('../views/pages/tracking/assetCategory/edit.vue'),
    },
    {
        path: '/tracking/command',
        name: 'trackingCommand',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/COMMANDS'],
        },
        component: () => import('../views/pages/tracking/command/index'),
    },
    {
        path: '/tracking/geofence',
        name: 'tracking-geofence',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/GEOFENCING'],
        },
        component: () => import('../views/pages/tracking/geoFence/index'),
    },
    {
        path: '/tracking/eventio',
        name: 'trackingEvent',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/EVENT_IO'],
        },
        component: () => import('../views/pages/tracking/eventio/index'),
    },
    {
        path: '/tracking/eventio/addeventio',
        name: 'AddEventio',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/EVENT_IO/CREATE'],
        },
        component: () => import('../views/pages/tracking/eventio/addEventio'),
    },
    {
        path: '/tracking/eventAction/addneweventaction',
        name: 'AddNewEventAction',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/EVENT_IO/CREATE'],
        },
        component: () => import('../views/pages/tracking/eventAction/AddNewEventAction'),
    },

    {
        path: '/tracking/command/addnewcommand',
        name: 'AddNewCommand',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/COMMANDS/CREATE'],
        },
        component: () => import('../views/pages/tracking/command/AddNewCommand'),
    },

    {
        path: '/tracking/eventAction/editeventaction/:id',
        name: 'EditEventAction',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/EVENT_IO/EDIT'],
        },
        component: () => import('../views/pages/tracking/eventAction/EditNewEventAction'),
    },
    {
        path: '/tracking/eventio/editeventio/:id',
        name: 'EditEventio',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/EVENT_IO/EDIT'],
        },
        component: () => import('../views/pages/tracking/eventio/editeventio'),
    },
    {
        path: '/tracking/command/editcommand/:id',
        name: 'EditCommand',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/COMMANDS/EDIT'],
        },
        component: () => import('../views/pages/tracking/command/EditNewCommand'),
    },

    {
        path: '/tracking/driver',
        name: 'trackingDriver',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/DRIVER'],
        },
        component: () => import('../views/pages/tracking/driver/index'),
    },
    {
        path: '/tracking/workbench',
        name: 'trackingWorkbench',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/WORKBENCH'],
        },
        component: () => import('../views/pages/tracking/workbench/index'),
    },
    {
        path: '/tracking/eventAction',
        name: 'trackingEventAction',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/EVENT_IO/CREATE'],
        },
        component: () => import('../views/pages/tracking/eventAction/index'),
    },
    {
        path: '/tracking/reports/hotspot',
        name: 'trackingReportHotSpots',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/REPORTS'],
        },
        component: () => import('../views/pages/tracking/report/hotspot'),
    },
    {
        path: '/tracking/reports/emailslogs',
        name: 'email-logs-reports',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/REPORTS'],
        },
        component: () => import('../views/pages/tracking/report/emailReports/email_logs'),
    },
    {
        path: '/tracking/reports/smslogs',
        name: 'sms-logs-reports',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/REPORTS'],
        },
        component: () => import('../views/pages/tracking/report/smsReports/sms_logs'),
    },
    {
        path: '/tracking/reports/trips',
        name: 'trackingReportTrips',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/REPORTS'],
        },
        component: () => import('../views/pages/tracking/report/trips'),
    },
    {
        path: '/tracking/reports/assetsinuse',
        name: 'ReportAssetsInUse',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/REPORTS'],
        },
        component: () => import('../views/pages/tracking/report/AssetsInUse'),
    },
    {
        path: '/tracking/reports/assetsalerts',
        name: 'ReportAssetsAlerts',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/REPORTS'],
        },
        component: () => import('../views/pages/tracking/report/AssetsAlerts/Index.vue'),
    },
    {
        path: '/tracking/reports/userLoginReport',
        name: 'ReportUserLogin',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/REPORTS'],
        },
        component: () => import('../views/pages/tracking/report/userLoginReport'),
    },
    {
        path: '/tracking/reports/assetstatus',
        name: 'ReportAssetsStatus',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/REPORTS'],
        },
        component: () => import('../views/pages/tracking/report/AssetStatus'),
    },

    {
        path: '/tracking/reports/devicesstatus',
        name: 'ReportDeviceStatus',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/REPORTS'],
        },
        component: () => import('../views/pages/tracking/report/DeviceStatus'),
    },
    {
        path: '/tracking/reports/devicesoffline',
        name: 'ReportDevicesOffline',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/REPORTS'],
        },
        component: () => import('../views/pages/tracking/report/DevicesOfflineDuetoBatteryLow'),
    },
    {
        path: '/tracking/reports/fulltelemetry',
        name: 'Reportfulltelemetry',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/REPORTS'],
        },
        component: () => import('../views/pages/tracking/report/FullTelemetry'),
    },
    {
        path: '/tracking/reports/attachedassets',
        name: 'ReportAttachedAssets',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/REPORTS'],
        },
        component: () => import('../views/pages/tracking/report/AttachedAssets'),
    },
    {
        path: '/tracking/reports/unitage',
        name: 'trackingReportUnitAge',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/REPORTS'],
        },
        component: () => import('../views/pages/tracking/report/unitAgingStatus'),
    },
    {
        path: '/tracking/reports/tamper',
        name: 'trackingReportTamper',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/REPORTS'],
        },
        component: () => import('../views/pages/tracking/report/tamper'),
    },
    {
        path: '/tracking/reports/specific_events',
        name: 'trackingReportSpecificEvents',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/REPORTS'],
        },
        component: () => import('../views/pages/tracking/report/specific_events'),
    },
    {
        path: '/tracking/reports/stoppage',
        name: 'trackingReportStoppage',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/REPORTS'],
        },
        component: () => import('../views/pages/tracking/report/stoppages'),
    },
    {
        path: '/tracking/reports/runtime',
        name: 'trackingReportEngineRunTime',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/REPORTS'],
        },
        component: () => import('../views/pages/tracking/report/engine'),
    },
    {
        path: '/tracking/reports/index',
        name: 'trackingReportIndex',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/REPORTS'],
        },
        component: () => import('../views/pages/tracking/report/index.vue'),
    },
    {
        path: '/tracking/schedulereport/index',
        name: 'trackingScheduleReportIndex',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/REPORTS'],
        },
        component: () => import('../views/pages/tracking/scheduleReport/index.vue'),
    },
    {
        path: '/tracking/schedulereport/edit/:id',
        name: 'trackingScheduleReportForm',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/REPORTS'],
        },
        component: () => import('../views/pages/tracking/scheduleReport/edit.vue'),
    },
    {
        path: '/tracking/schedulereport/create',
        name: 'trackingScheduleReportCreate',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/REPORTS'],
        },
        component: () => import('../views/pages/tracking/scheduleReport/create.vue'),
    },
    {
        path: '/tracking/reports/deviceUsage/:deviceID?',
        name: 'trackingReportDeviceUsage',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/REPORTS'],
        },
        component: () => import('../views/pages/tracking/report/deviceUsage/index.vue'),
    },
    {
        path: '/tracking/reports/deviceFullPosition/:deviceID?',
        name: 'trackingReportDeviceFullPosition',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/REPORTS'],
        },
        component: () => import('../views/pages/tracking/report/deviceFullPosition/index.vue'),
    },
    {
        path: '/tracking/reports/battery/:deviceID?',
        name: 'trackingReportBattery',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/REPORTS'],
        },
        component: () => import('../views/pages/tracking/report/battery/index.vue'),
    },
    {
        path: '/tracking/reports/geoFence/:deviceID?',
        name: 'trackingReportGeoFence',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/REPORTS'],
        },
        component: () => import('../views/pages/tracking/report/geoFence/index.vue'),
    },
    {
        path: '/tracking/usertracking',
        name: 'trackingUserTRACKING',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['TRACKING/USERS'],
        },
        component: () => import('../views/pages/tracking/userTracking/index'),
    },
    {
        path: '/tracking/sims/list',
        name: 'clients-sims-list',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN', 'CLIENTS', 'TRACKING/SIMS'],
        },
        component: () => import('../views/pages/tracking/sims/list'),
    },
    {
        path: '/tracking/sims/create',
        name: 'clients-sims-create',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN', 'CLIENTS', 'TRACKING/SIMS/CREATE'],
        },
        component: () => import('../views/pages/tracking/sims/create'),
    },
    {
        path: '/tracking/sims/:id/edit',
        name: 'clients-sims-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN', 'CLIENTS', 'TRACKING/SIMS/EDIT'],
        },
        component: () => import('../views/pages/tracking/sims/edit'),
    },
    {
        path: '/admin/tenants/list',
        name: 'tenants-lists',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/TENANTS'],
        },
        component: () => import('../views/pages/admin/tenants/tenants-list'),
    },
    {
        path: '/admin/tenants/create',
        name: 'tenants-create',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/TENANTS/CREATE'],
        },
        component: () => import('../views/pages/admin/tenants/tenants-create'),
    },
    {
        path: '/admin/tenants/edit/:id',
        name: 'tenants-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/TENANTS/EDIT'],
        },
        component: () => import('../views/pages/admin/tenants/tenants-edit'),
    },
    {
        path: '/admin/tenants/alertslist',
        name: 'tenants-alert-lists',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/TENANTS/ALERTS'],
        },
        component: () => import('../views/pages/admin/tenants/tenants-alerts'),
    },
    {
        path: '/admin/tenants/alertsEdit/:id',
        name: 'tenants-alert-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/TENANTS/ALERTS/EDIT'],
        },
        component: () => import('../views/pages/admin/tenants/tenants-alerts-edit'),
    },
    {
        path: '/vehicles/auctions/bids',
        name: 'vehicle-auction-bids',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['VEHICLES/AUCTIONS'],
        },
        component: () => import('../views/pages/vehicles/bids'),
    },
    {
        path: '/vehicles/repossessions',
        name: 'repossessions-list',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['VEHICLES/REPOSSESSIONS'],
        },
        component: () => import('../views/pages/vehicles/repossessions-list'),
    },
    {
        path: '/vehicles/repossessions/dispatchdetails/:id',
        name: 'vehicle-repossession-dispatch-details',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['VEHICLES/REPOSSESSIONS/DISPATCH'],
        },
        component: () => import('../views/pages/vehicles/vehicle-repossession-dispatchdetails'),
    },
    {
        path: '/vehicles/repossessions/edit/:id',
        name: 'vehicle-repossession-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['VEHICLES/REPOSSESSIONS/EDIT'],
        },
        component: () => import('../views/pages/vehicles/vehicle-repossession-edit'),
    },
    {
        path: '/vehicles/vehicle-repossession/:id',
        name: 'vehicle-repossession',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['VEHICLES/REPOSSESSIONS'],
        },
        component: () => import('../views/pages/vehicles/vehicle-repossession-create'),
    },
    {
        path: '/vehicles/repossessions/sightings',
        name: 'vehicle-sightings-list',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['VEHICLES/REPOSSESSIONS/SIGHTINGS'],
        },
        component: () => import('../views/pages/vehicles/sightings-list'),
    },
    {
        path: '/vehicles/sightings/edit/:id',
        name: 'vehicle-sightings-edit',
        meta: {
            authRequired: true,
            layout: main,
            claims: {},
            allowedPermissions: ['VEHICLES/REPOSSESSIONS/SIGHTINGS/EDIT'],
        },
        component: () => import('../views/pages/vehicles/vehicle-sightings-edit'),
    },
    {
        path: '/vehicles/vehicle-sighting/:id',
        name: 'vehicle-sighting',
        meta: {
            authRequired: true,
            layout: main,
            claims: {},
            allowedPermissions: ['VEHICLES/REPOSSESSIONS/SIGHTINGS/EDIT'],
        },
        component: () => import('../views/pages/vehicles/vehicle-sighting'),
    },
    {
        path: '/api-user/add',
        name: 'Add API User',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/API/CREATE'],
        },
        component: () => import('../views/pages/admin/api-user/add-api-user'),
    },
    {
        path: '/vehicles/create',
        name: 'vehicles-create',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['VEHICLES/CREATE'],
        },
        component: () => import('../views/pages/vehicles/vehicle-create'),
    },
    {
        path: '/vehicles/list',
        name: 'vehicles',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['VEHICLES'],
        },
        component: () => import('../views/pages/vehicles/vehicle-list'),
    },
    {
        path: '/vehicles/edit/:id',
        name: 'vehicles-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['VEHICLES/EDIT'],
        },
        component: () => import('../views/pages/vehicles/vehicle-edit'),
    },
    {
        path: '/vehicles/categories',
        name: 'vehicles-categories',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['VEHICLES/CATEGORIES'],
        },
        component: () => import('../views/pages/vehicles/vehicle-categories'),
    },
    {
        path: '/vehicles/categories/create',
        name: 'vehicles-categories-create',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['VEHICLES/CATEGORIES/CREATE'],
        },
        component: () => import('../views/pages/vehicles/vehicle-categories-create'),
    },
    {
        path: '/vehicles/categories/edit',
        name: 'vehicles-categories-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['VEHICLES/CATEGORIES/EDIT'],
        },
        component: () => import('../views/pages/vehicles/vehicle-categories-edit'),
    },
    {
        path: '/admin/api-users/add',
        name: 'add-api-user',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/API/CREATE'],
        },
        component: () => import('../views/pages/admin/api-user/add-api-user'),
    },
    {
        path: '/admin/api-users/list',
        name: 'list-api-user',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/API/EDIT'],
        },
        component: () => import('../views/pages/admin/api-user/list'),
    },
    {
        path: '/admin/api-users/grid',
        name: 'grid-api-user',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/API'],
        },
        component: () => import('../views/pages/admin/api-user/grid'),
    },
    {
        path: '/admin/users/list',
        name: 'userslist',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/USERS'],
        },
        component: () => import('../views/pages/admin/users/list'),
    },
    {
        path: '/admin/users/list-deleted',
        name: 'userslistdeleted',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/USERS/DELETE'],
        },
        component: () => import('../views/pages/admin/users/list-deleted'),
    },
    {
        path: '/admin/users/grid',
        name: 'usersgrid',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/USERS'],
        },
        component: () => import('../views/pages/admin/users/grid'),
    },
    {
        path: '/admin/users/add',
        name: 'users-add',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/USERS/CREATE'],
        },
        component: () => import('../views/pages/admin/users/user-add'),
    },
    {
        path: '/admin/users/edit/:id',
        name: 'users-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/USERS/EDIT'],
        },
        component: () => import('../views/pages/admin/users/user-edit'),
    },
    {
        path: '/admin/roles/list',
        name: 'list roles',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/ROLES'],
        },
        component: () => import('../views/pages/admin/role/role-list'),
    },
    {
        path: '/admin/roles/add',
        name: 'add roles',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/ROLES/CREATE'],
        },
        component: () => import('../views/pages/admin/role/role-add'),
    },
    {
        path: '/admin/roles/edit/:id',
        name: 'edit role',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/ROLES/EDIT'],
        },
        component: () => import('../views/pages/admin/role/role-edit'),
    },
    {
        path: '/csv/upload/:model?',
        name: 'upload-file',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['SALES/LEADS', 'VEHICLES', 'CLIENTS', 'TRACKING/DEVICE', 'TRACKING/SIMS', 'TRACKING/ASSETS'],
        },
        component: () => import('../views/pages/csv-upload/index'),
    },
    {
        path: '/admin/data-models/list',
        name: 'data-models',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/DATA_MODELS'],
        },
        component: () => import('../views/pages/data-model/list'),
    },
    //Do not delete this route
    {
        path: '/admin/data-models/create',
        name: 'data-models-create',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/DATA_MODELS/CREATE'],
        },
        component: () => import('../views/pages/data-model/create'),
    },
    {
        path: '/admin/data-models/edit/:id',
        name: 'data-models-edit',
        meta: {
            authRequired: true,
            claims: {},
            allowedPermissions: ['ADMIN/DATA_MODELS/EDIT'],
        },
        component: () => import('../views/pages/data-model/edit'),
    },
    {
        path: '/admin/template/list',
        name: 'template-list',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/TEMPLATES'],
        },
        component: () => import('../views/pages/admin/templates/template-list'),
    },
    {
        path: '/admin/template/add',
        name: 'template-add',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/TEMPLATES/CREATE'],
        },
        component: () => import('../views/pages/admin/templates/template-add'),
    },
    {
        path: '/admin/template/edit/:id',
        name: 'template-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/TEMPLATES/EDIT'],
        },
        component: () => import('../views/pages/admin/templates/template-edit'),
    },
    {
        path: '/saml',
        name: 'saml',
        component: () => import('../views/pages/saml/index'),
    },
    {
        path: '/not-authorized',
        name: 'not-authorized',
        meta: {
            authRequired: false,
        },
        component: () => import('../views/pages/utility/not-authorized'),
    },
    {
        path: '/reports',
        name: 'reports',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/REPORTS'],
        },
        component: () => import('../views/pages/reports/custom'),
    },
    {
        path: '/reports/create',
        name: 'reports-create',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/REPORTS/CREATE'],
        },
        component: () => import('../views/pages/reports/custom/create'),
    },
    {
        path: '/reports/:id/edit',
        name: 'reports-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/REPORTS/EDIT'],
        },
        component: () => import('../views/pages/reports/custom/edit'),
    },
    {
        path: '/reports/categories',
        name: 'report-categories',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/REPORTCATEGORIES'],
        },
        component: () => import('../views/pages/reports/categories'),
    },
    {
        path: '/reports/categories/create',
        name: 'reports-categories-create',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/REPORTCATEGORIES/CREATE'],
        },
        component: () => import('../views/pages/reports/categories/create'),
    },
    {
        path: '/reports/categories/:id/edit',
        name: 'reports-categories-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/REPORTCATEGORIES/EDIT'],
        },
        component: () => import('../views/pages/reports/categories/edit'),
    },
    {
        path: '/admin/ip-addresses/create',
        name: 'ip-addresses-create',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/IPADDRESSES/CREATE'],
        },
        component: () => import('../views/pages/admin/ip-addresses/create'),
    },
    {
        path: '/admin/ip-addresses/:id/edit',
        name: 'ip-addresses-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/IPADDRESSES/EDIT'],
        },
        component: () => import('../views/pages/admin/ip-addresses/edit'),
    },
    {
        path: '/admin/ip-addresses/list',
        name: 'ip-addresses-list',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/IPADDRESSES'],
        },
        component: () => import('../views/pages/admin/ip-addresses/list'),
    },
    {
        path: '/admin/settings',
        name: 'settings',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/SETTINGS'],
        },
        component: () => import('../views/pages/admin/settings/list'),
    },
    {
        path: '/account/verification/:userId/:token',
        name: 'account-verification',
        component: () => import('../views/pages/account/verification'),
    },
    // final catch-all for 404 errors
    {
        path: '/coming-soon',
        name: 'coming-soon',
        meta: {
            authRequired: false,
        },
        component: () => import('../views/pages/utility/comingsoon'),
    },
    {
        path: '/offline',
        name: 'offline',
        meta: {
            layout: main,
            offline: true,
            authRequired: false,
        },
        component: () => import('../views/pages/utility/offline'),
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        meta: {
            authRequired: false,
        },
        component: () => import('../views/pages/utility/404'),
    },
    {
        path: '/vehicles/vehicle-spotter',
        name: 'vehicle-spotter',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['VEHICLES/SPOTTERS'],
        },
        component: () => import('../views/pages/vehicles/vehicle-spotter'),
    },
    {
        path: '/vehicles/vehicle-spotter-edit/:personId',
        name: 'vehicle-spotter-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['VEHICLES/SPOTTERS/EDIT'],
        },
        component: () => import('../views/pages/vehicles/vehicle-spotter-edit'),
    },
    {
        path: '/vehicles/third-party-list',
        name: 'third-party',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['VEHICLES/3RDPARTIES'],
        },
        component: () => import('../views/pages/vehicles/third-party-list'),
    },

    {
        path: '/vehicles/third-party-edit/:personId',
        name: 'third-party-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['VEHICLES/3RDPARTIES/EDIT'],
        },
        component: () => import('../views/pages/vehicles/third-party-edit'),
    },

    {
        path: '/vehicles/blue-book-create',
        name: 'blue-book-create',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN', 'VEHICLES'],
        },
        component: () => import('../views/pages/vehicles/blue-book-create'),
    },

    {
        path: '/vehicles/bluebook-list',
        name: 'bluebookList',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN', 'VEHICLES'],
        },
        component: () => import('../views/pages/vehicles/bluebook-list'),
    },
    {
        path: '/vehicles/bluebook-edit/:id',
        name: 'bluebook-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN', 'VEHICLES'],
        },
        component: () => import('../views/pages/vehicles/bluebook-edit'),
    },
];
